import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { OverlayModule } from 'primeng/overlay';

import { APP_PERMISSION } from '@core/enum/permisson';
import { LeadNote, LeadReplyNote } from '@core/models/interfaces/lead/lead-notes';
import { PolicyDetails } from '@core/models/interfaces/lead/lead-policys';
import { NoteStatus } from '@core/models/interfaces/note';
import { NoteService } from '@core/services/note.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { OptionStatusDropdownComponent } from '@shared/components/option-status-dropdown/option-status-dropdown.component';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { UserDetail } from '@core/models/interfaces/user';
import { Store } from '@ngrx/store';
import { checkTimeLimit } from '@shared/utils/checkTimeLimit';
import { AppState } from '@state/app.state';
import { selectUserInfo } from '@state/auth/auth.selectors';
import { Subject, takeUntil } from 'rxjs';

export interface NoteUpdateData {
  id: string;
  statusId: string;
  policyId: string;
  notes: string;
  isPin: boolean;
}

@Component({
  selector: 'app-lead-note-item',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    OptionStatusDropdownComponent,
    CustomAvatarComponent,
    InputTextModule,
    OverlayModule,
    TranslateModule,
    ButtonComponent,
    DropdownModule,
    MenuModule,
    InputTextareaModule,
    OverlayModule,
    NgxPermissionsModule
  ],
  templateUrl: './lead-note-item.component.html',
  styleUrl: './lead-note-item.component.scss'
})
export class LeadNoteItemComponent implements OnInit, OnChanges, AfterViewInit {
  @Input({ required: true }) note: LeadNote;
  @Input() leadId = '';
  @Input() isExpanded = false;
  @Input() totalStatuses: number = 0;
  @Input() statuses: NoteStatus[] = [];
  @Input() policies: PolicyDetails[] = [];
  @Input() view: string;

  @Output() onRefresh = new EventEmitter();
  @Output() deleteNote = new EventEmitter();
  @Output() refreshLayout = new EventEmitter();

  @ViewChild('noteElement') noteElement: ElementRef<HTMLDivElement>;

  readonly unsubscribe$ = new Subject();
  readonly Permissions = APP_PERMISSION;
  readonly translatePrefix = 'lead-details.notes.';
  readonly messagePrefix = 'lead-details.message.';
  readonly actions: MenuItem[] = [
    {
      id: 'EDIT',
      label: 'edit',
      icon: 'icon-wl-edit',
      command: () => this.onEdit(),
      permission: APP_PERMISSION.NOTE_APPEND_EDIT,
      permissionEditAnyNote: APP_PERMISSION.EDIT_ANY_NOTE
    },
    {
      id: 'DELETE',
      label: 'delete',
      icon: 'icon-wl-delete',
      styleClass: 'error-color',
      command: () => this.onDelete(this.note.id, 'NOTE'),
      permission: APP_PERMISSION.NOTE_APPEND_DELETE,
      permissionDeleteAnyNote: APP_PERMISSION.DELETE_ANY_NOTE
    }
  ];

  readonly appendActions: MenuItem[] = [
    {
      id: 'EDIT',
      label: 'edit',
      icon: 'icon-wl-edit',
      command: () => {
        this.isEditAppend = true;
      },
      permission: APP_PERMISSION.NOTE_APPEND_EDIT,
      permissionEditAnyNote: APP_PERMISSION.EDIT_ANY_NOTE
    },
    {
      id: 'DELETE',
      label: 'delete',
      icon: 'icon-wl-delete',
      styleClass: 'error-color',
      command: () => {
        this.onDelete(this.appendIdSelected, 'APPEND');
      },
      permission: APP_PERMISSION.NOTE_APPEND_DELETE,
      permissionDeleteAnyNote: APP_PERMISSION.DELETE_ANY_NOTE
    }
  ];

  isLarge = false;
  isViewMore = true;
  isEditAppend = false;
  isEdit = false;
  isComment = false;
  isMenu = false;
  visible = false;

  appendIdSelected = '';
  appendNote: string = '';
  noteValue = '';
  isUpdating = false;
  isPin = false;
  statusInit: NoteStatus;
  statusId = '';
  policyId = '';

  replyNote = '';
  userInfo: UserDetail;
  expandedReplies: { [key: string]: boolean } = {};
  hasPermissionAddAppend: boolean = false;
  isSubmitReply: boolean = false;
  hasPermissionEditAnyNote: boolean = false;
  hasPermissionDeleteAnyNote: boolean = false;

  constructor(
    private noteService: NoteService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private permissionsService: NgxPermissionsService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectUserInfo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userInfo => {
        if (userInfo) {
          this.userInfo = userInfo;
        }
      });

    this.note.replyNotes.forEach(reply => {
      this.expandedReplies[reply.id] = true;
    });

    this.hasPermissionAddAppend = Boolean(this.permissionsService.getPermission(APP_PERMISSION.NOTE_ADD_APPEND));
    this.hasPermissionEditAnyNote = Boolean(this.permissionsService.getPermission(APP_PERMISSION.EDIT_ANY_NOTE));
    this.hasPermissionDeleteAnyNote = Boolean(this.permissionsService.getPermission(APP_PERMISSION.DELETE_ANY_NOTE));
  }

  ngAfterViewInit(): void {
    this.checkNoteIsLarge();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['note']?.currentValue) {
      this.policyId = this.note?.policy?.id;
      this.isPin = Boolean(this.note.isPin);
      this.noteValue = this.note.notes;
      this.statusInit = { ...this.note.status };
    }
  }

  checkNoteIsLarge() {
    this.isLarge = this.noteElement.nativeElement.clientHeight > 85;
  }

  onCancel() {
    this.isEdit = false;
    this.noteValue = this.note.notes;
    this.statusInit = { ...this.note.status };

    this.refreshLayout.emit();
  }

  onDelete(id: string, type: 'NOTE' | 'APPEND') {
    this.confirmationService.confirm({
      header: this.translateService.instant(this.messagePrefix + (type === 'NOTE' ? 'delete-note' : 'delete-append')),
      message: this.translateService.instant(
        this.messagePrefix + (type === 'NOTE' ? 'confirm-delete-note' : 'confirm-delete-append')
      ),
      acceptButtonStyleClass: 'btn-xl btn-danger',
      rejectButtonStyleClass: 'btn-xl btn-outline-secondary',
      rejectLabel: this.translateService.instant('action.cancel'),
      acceptLabel: this.translateService.instant('action.delete'),
      acceptIcon: 'none',
      rejectIcon: 'none',

      accept: () => {
        this.noteService.deleteLeadNoteById(id).subscribe({
          next: () => {
            if (type === 'NOTE') {
              this.onRefresh.emit();
              this.deleteNote.emit();
            } else {
              this.note.replyNotes = [...this.note.replyNotes].filter(item => item.id !== id);
            }

            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant(
                this.messagePrefix + (type === 'NOTE' ? 'delete-note-success' : 'delete-append-success')
              )
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              detail: this.translateService.instant(
                this.messagePrefix + (type === 'NOTE' ? 'delete-note-failed' : 'delete-append-failed')
              )
            });
          }
        });
      }
    });
  }

  onEdit() {
    this.isEdit = true;
    this.refreshLayout.emit();
  }

  onSelectReply(reply: LeadReplyNote) {
    this.appendIdSelected = reply.id;
    this.appendNote = reply.notes;
  }

  onEditAppend(event: Event, index: number) {
    event.preventDefault();
    this.noteService
      .updateLeadNoteById(this.appendIdSelected, { notes: this.appendNote })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.note.replyNotes[index].notes = this.appendNote;
          this.appendNote = '';
          this.isEditAppend = false;
          this.messageService.add({
            severity: 'success',
            detail: 'Update append note successfully'
          });
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            detail: 'Update append note failed'
          });
        }
      });
  }

  onCancelEditAppend() {
    this.appendNote = '';
    this.isEditAppend = false;
  }

  onUpdate() {
    this.isUpdating = true;
    this.noteService
      .updateLeadNoteById(this.note.id, {
        statusId: this.statusId || this.note.status?.id,
        notes: this.noteValue,
        isPin: this.isPin,
        leadId: this.leadId,
        policyId: this.policyId
      })
      .subscribe({
        next: res => {
          this.isUpdating = false;
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant(this.messagePrefix + 'update-note-success')
          });
          this.note = res?.data;

          this.isEdit = false;
          this.checkNoteIsLarge();
          this.onRefresh.emit();
        },
        error: () => {
          this.isUpdating = false;
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant(this.messagePrefix + 'update-note-failed')
          });
        }
      });
  }

  onReply(event: Event) {
    event.preventDefault();
    if (!this.replyNote.trim() || this.isSubmitReply) return;
    this.isSubmitReply = true;
    this.noteService.replyLeadNote(this.note.id, this.replyNote).subscribe({
      next: res => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant(this.messagePrefix + 'reply-success')
        });
        this.expandedReplies[res.data.id] = true;
        this.note.replyNotes?.unshift(res.data);
        this.refreshLayout.emit();
        this.replyNote = '';
        this.isSubmitReply = false;
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant(this.messagePrefix + 'reply-failed')
        });
        this.isSubmitReply = false;
      }
    });
  }

  onPin() {
    if (this.isUpdating) return;

    this.isUpdating = true;
    this.isPin = !this.isPin;
    this.noteService
      .updateLeadNoteById(this.note.id, {
        statusId: this.note.status?.id ?? '00000000-0000-0000-0000-000000000000',
        notes: this.note.notes,
        isPin: this.isPin,
        leadId: this.leadId,
        policyId: this.policyId
      })
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant(
              this.messagePrefix + (this.isPin ? 'pin-note-success' : 'unpin-note-success')
            )
          });
          this.isUpdating = false;
          // this.onRefresh.emit();
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant(
              this.messagePrefix + (this.isPin ? 'pin-note-failed' : 'unpin-note-failed')
            )
          });
          this.isUpdating = false;
        }
      });
  }

  onAppends() {
    if (this.note.replyNotes?.length > 0 || this.permissionsService.getPermission(this.Permissions.NOTE_ADD_APPEND)) {
      this.isComment = !this.isComment;
      this.refreshLayout.emit();
    }
  }

  onToggleViewMore() {
    this.isViewMore = !this.isViewMore;
    this.refreshLayout.emit();
  }

  isCreatedIn30Minutes(createdById?: string, createdAt?: string) {
    return this.userInfo.id === createdById && checkTimeLimit(createdAt, 30);
  }

  isCreatedByOwner(createdById?: string) {
    return this.userInfo.id === createdById;
  }

  get fullNameCreatedBy() {
    return this.note.createdBy?.fullName || '';
  }

  toggleReply(replyId: string) {
    this.expandedReplies[replyId] = !this.expandedReplies[replyId];
  }
}
