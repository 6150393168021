<div
  class="px-2 border border-transparent group rounded-sm flex items-center justify-between"
  [ngClass]="[
    inputClass,
    visible ? 'bg-neutral-200 transition ' : '',
    disabled ? '' : 'hover:border-neutral-300 cursor-pointer'
  ]"
  (click)="onToggleVisible()">
  <div
    [style.color]="initValue?.color"
    [style.backgroundColor]="initValue?.backgroundColor"
    class="rounded text-2 px-2 py-[2px] font-medium bg-neutral-200"
    [pTooltip]="initValue?.name || 'Unidentified'"
    [ngClass]="isQuickAction ? 'max-w-[240px] truncate' : 'max-w-[260px] truncate'">
    {{ initValue?.name || 'Unidentified' }}
  </div>

  @if (!disabled) {
    <i
      [ngClass]="showIcon ? '' : 'opacity-0 group-hover:opacity-100'"
      class="flex icon-wl-arrow-drop-down text-6 h-5 items-center"></i>
  }
</div>
<p-overlay appendTo="body" [(visible)]="visible" (onHide)="onHide()">
  <div class="bg-white min-w-[160px] border rounded-sm">
    <app-search-input [inputClass]="'h-8'" [debounceTime]="300" (onSearch)="onSearchType($event)"></app-search-input>
    <div
      class="px-1 py-2 w-full max-h-[200px] overflow-y-auto relative"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      (scrolled)="onScroll()">
      @if (options.length) {
        @for (item of options; track $index) {
          <div
            (click)="onChangeStatus(item)"
            class="py-2 pl-9 rounded hover:bg-neutral-600/10 relative cursor-pointer"
            [ngClass]="{
              'bg-neutral-600/10': item.id === (selected || initValue?.id)
            }">
            @if (item.id === (selected || initValue?.id)) {
              <i class="icon-wl-check text-blue-600 text-6 absolute top-1/2 -translate-y-1/2 left-2"></i>
            }
            <div
              [style.color]="item.color"
              [style.backgroundColor]="item.backgroundColor"
              class="rounded text-2 px-2 py-[2px] font-medium w-max">
              {{ item.name }}
            </div>
          </div>
        }
      } @else {
        <div class="flex items-center justify-center flex-col gap-2 py-4">
          <div class="h-9 w-9 flex items-center justify-center bg-neutral-alpha-200A rounded-full">
            <i class="icon-wl-search text-6"></i>
          </div>

          <div>No data to display</div>
        </div>
      }
    </div>
    @if (isApplyBtn) {
      <div class="flex justify-end border-t py-2 px-4">
        <app-button size="xs" [label]="'action.apply' | translate" (onClick)="onApply()" />
      </div>
    }
  </div>
</p-overlay>
