import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { StatusOption } from '@core/models/interfaces/common';
import { LeadStatusService } from '@core/services/lead-status.service';
import { NoteService } from '@core/services/note.service';
import { TaskTypeService } from '@core/services/task/task-type.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { OverlayModule } from 'primeng/overlay';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, takeUntil } from 'rxjs';
import { SearchInputComponent } from '../search-input/search-input.component';

@Component({
  selector: 'app-option-status-dropdown',
  standalone: true,
  imports: [
    OverlayModule,
    ButtonComponent,
    TranslateModule,
    CommonModule,
    InfiniteScrollDirective,
    TooltipModule,
    SearchInputComponent
  ],
  templateUrl: './option-status-dropdown.component.html',
  styleUrl: './option-status-dropdown.component.scss'
})
export class OptionStatusDropdownComponent implements OnDestroy {
  @Input() type: string = '';
  @Input({ required: true }) visible = false;
  @Input({ required: true }) selected: string = '';
  @Input({ required: true }) options: StatusOption[] = [];
  @Input() totalOptions: number = 1000;
  @Input() disabled = false;
  @Input() isQuickAction: boolean;
  @Input() initValue?: StatusOption;
  @Input() inputClass = '';

  @Input() isApplyBtn = true;
  @Input() showIcon = true;

  @Output() selectedChange = new EventEmitter<string>();
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onApplyStatus = new EventEmitter<StatusOption>();

  readonly unsubscribe$ = new Subject();
  page = 0;
  keyword: string = '';

  constructor(
    private noteService: NoteService,
    private taskTypeService: TaskTypeService,
    private leadStatusService: LeadStatusService
  ) {}

  onToggleVisible() {
    if (this.disabled) return;
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible);
  }

  onHide() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  onChangeStatus(status: StatusOption) {
    this.selected = status.id;
    this.selectedChange.emit(this.selected);
    if (!this.isApplyBtn) {
      this.initValue = status;
      this.onHide();
      this.onApplyStatus.emit();
    }
  }

  onApply() {
    this.onApplyStatus.emit();
  }

  onScroll() {
    if (this.totalOptions <= this.options.length) return;
    this.page++;

    switch (this.type) {
      case 'NOTE': {
        this.noteService
          .getNoteStatuses(this.page, 10, 0, this.keyword)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: res => {
              this.options = [...this.options, ...res.data.content];
            }
          });

        break;
      }

      case 'TASK_TYPE': {
        this.taskTypeService
          .getTaskTypes({ page: this.page, keyword: this.keyword })
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: res => {
              this.options = [...this.options, ...res.data.content];
            }
          });

        break;
      }

      case 'LEAD_STATUS': {
        this.leadStatusService
          .getLeadStatuses({ page: this.page, keyword: this.keyword })
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: res => {
              this.options = [...this.options, ...res.data.content];
            }
          });
        break;
      }
    }
  }

  onSearchType(keyword: string) {
    this.keyword = keyword;
    this.page = 0;
    switch (this.type) {
      case 'NOTE': {
        this.noteService
          .getNoteStatuses(this.page, 10, 0, keyword)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: res => {
              this.options = res.data.content;
            }
          });

        break;
      }

      case 'TASK_TYPE': {
        this.taskTypeService
          .getTaskTypes({ page: this.page, keyword: keyword })
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: res => {
              this.options = res.data.content || [];
            }
          });

        break;
      }

      case 'LEAD_STATUS': {
        this.leadStatusService
          .getLeadStatuses({ page: this.page, keyword: keyword })
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: res => {
              this.options = res.data.content;
            }
          });
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
