import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeadSearchService {
  isGlobalSearch: boolean = false;

  private dataSubject = new BehaviorSubject<string>('');
  data$ = this.dataSubject.asObservable();

  constructor() {}

  onSearchLeads(data: string, isGlobalSearch: boolean) {
    this.isGlobalSearch = isGlobalSearch;
    this.dataSubject.next(data);
  }
}
